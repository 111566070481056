export const FirebaseConfig = {
	"projectId": "gettowd",
	"appId": "1:322957919376:web:9671f884246e95c0800415",
	"databaseURL": "https://gettowd-default-rtdb.firebaseio.com",
	"storageBucket": "gettowd.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyD_iHXtDq3aJUCZ1dpiwfd1m67TXALvW_k",
	"authDomain": "gettowd.firebaseapp.com",
	"messagingSenderId": "322957919376",
	"measurementId": "G-LW5NQYB26Z"
};