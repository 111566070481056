import React, { useState, useEffect} from 'react';
import { Grid, Typography, Button, Box, } from '@mui/material';
import backgroundImage from '../assets/img/background.jpg';
import useStyles from '../styles/styles';
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import { useSelector } from "react-redux";
import {calcEst, showEst, optionsRequired, FONT_FAMILY} from '../common/sharedFunctions';
import {colors as col} from "../components/Theme/WebTheme"

const Hero = (props) => {
  const classes = useStyles();
  const { t, i18n  } = useTranslation();
  const isRTL = i18n.dir();
  const navigate = useNavigate();
  const role = props.role;
  const [loggedIn, setLoggedIn] = useState(false);
  const auth = useSelector(state => state.auth);
  const settings = useSelector(state => state.settingsdata.settings);
  
  useEffect(()=>{
    if(auth.profile && auth.profile.uid){
      setLoggedIn(true);
    }else{
      setLoggedIn(false);
    }
  },[auth.profile]);

  return (
    <Box className={classes.heroBox} sx={{ backgroundImage: `url(${backgroundImage})`, backgroundSize: 'cover' }} style={{direction:isRTL === 'rtl'? 'rtl':'ltr'}}>
      <Grid container spacing={12} className={classes.gridContainer}>
        <Grid item xs={12} md={9}>
          <Typography variant="h3" fontFamily={'Comfortaa'} fontWeight={500} className={classes.titleMain} style={{color:'#1CACE1'}}>
          {t('book_your_title')}
          </Typography>
          
          <Grid item xs={12} md={12}>
          <Typography variant="h5" fontFamily={'Comfortaa'} fontWeight={400} className={classes.subtitleMain}>
          {t('mobile_apps_on_store')}
          </Typography>
          {settings && settings.AppleStoreLink?
            <a href={settings.AppleStoreLink}><img src={require("../assets/img/appstore.png").default} alt="Apple Store Link"/></a>
            :null}
            <span style={{marginRight: '5px'}}></span>
            {settings && settings.PlayStoreLink?
            <a href={settings.PlayStoreLink}><img src={require("../assets/img/playstore.png").default} alt="Playstore Link"/></a>
            :null}
        </Grid>

          <Typography variant="h6" fontFamily={'Comfortaa'} className={classes.subtitleMain} style={{color:'white'}}>
           {t('about_us_content2')}
          </Typography>
            <Button
              variant="contained"
              
              sx={{ width: '200px', fontSize: '16px', fontFamily:'Comfortaa' }}
              onClick={(e) => { e.preventDefault();
                if(loggedIn){
                 navigate( ((role && role === 'driver') || (calcEst && !showEst && !optionsRequired && (role === 'admin' || role === 'fleetadmin'))) ? '/bookings' : '/addbookings')
                }else{
                  navigate('/login')
                }
              }}
            >
            {role === 'driver' || (calcEst && !showEst && !optionsRequired &&  (role === 'admin' || role === 'fleetadmin'))? t('info') : t('book_your_ride_menu')}
            </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Hero;